import ABSService from "./absService";

class AppGroupService extends ABSService {
    constructor() {
        super("app-group");
    }

    login = async (loginData) => {
        let loginResult = this.requestHelper.post('/api/login', loginData);
        return loginResult;
    }

    goToApp = async(appId) => {
        const data = {appId};
        console.log(appId);
        const appLink = await this.requestHelper.post('/api/create-app-link', {appId});
        window.location.href = appLink.url;
    }
}

export default AppGroupService;